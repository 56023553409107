$(document).ready(function() {
	var form = $('#contact_form');
	var button = $('#contact_form_send_button');
	var originalButtonText = button.html();
	var message = $('#contact_form_message');

	form.on('submit', function(e) {
		e.preventDefault();
		updateButton();
		sendAjax();
	});

	var sendAjax = function() {
		$.ajax({
		type:'POST',
		url:'/contact',
		data:form.serialize(),
		success: function(data) {
				if (data.status == 'success') {	
					showMessage();
					setTimeout(function() {
						resetForm();
					}, 3000);
				}
			}
		});
	}

	var updateButton = function() {
		button.html('Sending...');
		button.prop('disabled', true);
	}

	var showMessage	= function() {
		message.css('display', 'flex').hide().fadeIn('fast');
	}

	var resetForm = function() {
		form.trigger('reset');
		button.html(originalButtonText);
		button.prop('disabled', false);
		message.fadeOut('fast');
	}
});